import * as React from "react";
import { css, withTheme } from "@delight-js/react";
import Hero from "../components/Hero";
import ContentBox from "../components/layout/ContentBox";
import TeaserSlider from "../components/TeaserSlider";
import NewsletterBlock from "../components/NewsletterBlock";
import SharingBar from "../components/SharingBar";

const HomePage = withTheme(({ theme, ...props }) => {
  const {
    pageData: { hero, content, locale, fullUrl },
    groupedTeaserItems,
    translations,
  } = props.pageContext;

  let subline = "";

  if (hero.subline) {
    const splittedSubline = hero.subline.split("|");
    subline = splittedSubline
      .map(
        (item, index, { length }) =>
          item.trim().replace(/ /g, "\u00a0") +
          (length > 1 + index ? "\u00a0| " : "")
      )
      .join("");
  }

  return (
    <React.Fragment>
      {!!hero && (
        <Hero
          overline={hero.overline}
          headline={hero.headline}
          subline={subline}
          image={hero.image.localFile}
        />
      )}

      <ContentBox content={content} css={css`$my26;`(theme)} locale={locale} />
      {["recipes", "stories", "videos"].map((group, index, original) => (
        <TeaserSlider
          key={group}
          css={
            index === original.length - 1
              ? css`$my26;`(theme)
              : css`$mt26;`(theme)
          }
          items={groupedTeaserItems[group]}
          headline={translations.teaser[group].headline}
          button={translations.teaser[group].button}
        />
      ))}
      <SharingBar url={fullUrl} css={css`$mb26;`(theme)} />
      <NewsletterBlock css={css`$mb26;`(theme)} locale={locale} />
    </React.Fragment>
  );
});

export default HomePage;
